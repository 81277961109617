import React from 'react';

import { language } from './language';

// Datos de entrada necesarios del motor de búsqueda
export const datosEntrada = [
  {
    id: 1,
    name: language["seleccione-fechas"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'calendario',
    class: 'relative',
    idName: 'parentElementoPicker'
  },
  {
    id: 2,
    name: language["seleccione-huespedes"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'personas',
    class: '',
    idName: 'divContainerHuespedes'
  },
  {
    id: 3,
    name: language["codigo-promocional"][Liferay.ThemeDisplay.getLanguageId()],
    icon: 'codigo',
    class: '',
    idName: ''
  }
]
