// Dependencias
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// Componentes
import CloseBtn from './CloseBtn';
import InputSearch from './InputSearch';
import ButtonSubmit from './ButtonSubmit';
import * as customDatepicker from './customDatepicker.js'

// Contexto
import { ParametrosBusquedaProvider } from './parametrosBusqueda';

// Datos
import { datosEntrada } from './constans';
import { language } from './language';

import { Liferay as LiferayAPI } from './common/services/liferay/liferay';
import apicommon from './common/services/liferay/apicommon';

const MotorBusqueda = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const MotorBusqueda = useRef();
  const [urlFlight, setUrlFlight] = useState('');
  const [datepicker, setDatepicker] = useState('');
  
  useEffect(() => {
		async function getUrlFlight() {
			var newUrlFlight = Liferay.ThemeDisplay.getLanguageId().split("_")[0];
			setUrlFlight(newUrlFlight);
		};
			
		if(!urlFlight)
			getUrlFlight();
	}, []);
	
	useEffect(() => {
			async function getDatepicker() {
				var language = {
				      Enero: {
				        es_ES: "Enero",
				        en_US: "January",
				        de_DE: "Januar",
				      },
				      Febrero: {
				        es_ES: "Febrero",
				        en_US: "February",
				        de_DE: "Februar",
				      },
				      Marzo: {
				        es_ES: "Marzo",
				        en_US: "March",
				        de_DE: "Marsch",
				      },
				      Abril: {
				        es_ES: "Abril",
				        en_US: "April",
				        de_DE: "April",
				      },
				      Mayo: {
				        es_ES: "Mayo",
				        en_US: "May",
				        de_DE: "Mai",
				      },
				      Junio: {
				        es_ES: "Junio",
				        en_US: "June",
				        de_DE: "Juni",
				      },
				      Julio: {
				        es_ES: "Julio",
				        en_US: "July",
				        de_DE: "Juli",
				      },
				      Agosto: {
				        es_ES: "Agosto",
				        en_US: "August",
				        de_DE: "August",
				      },
				      Septiembre: {
				        es_ES: "Septiembre",
				        en_US: "September",
				        de_DE: "September",
				      },
				      Octubre: {
				        es_ES: "Octubre",
				        en_US: "October",
				        de_DE: "Oktober",
				      },
				      Noviembre: {
				        es_ES: "Noviembre",
				        en_US: "November",
				        de_DE: "November",
				      },
				      Diciembre: {
				        es_ES: "Diciembre",
				        en_US: "December",
				        de_DE: "Dezember",
				      },
				      Lunes: {
				        es_ES: "Lunes",
				        en_US: "Monday",
				        de_DE: "Montag",
				      },
				      Martes: {
				        es_ES: "Martes",
				        en_US: "Tuesday",
				        de_DE: "Dienstag",
				      },
				      Miércoles: {
				        es_ES: "Miércoles",
				        en_US: "Wednesday",
				        de_DE: "Mittwoch",
				      },
				      Jueves: {
				        es_ES: "Jueves",
				        en_US: "Thursday",
				        de_DE: "Donnerstag",
				      },
				      Viernes: {
				        es_ES: "Viernes",
				        en_US: "Friday",
				        de_DE: "Freitag",
				      },
				      Sábado: {
				        es_ES: "Sábado",
				        en_US: "Saturday",
				        de_DE: "Samstag",
				      },
				      Domingo: {
				        es_ES: "Domingo",
				        en_US: "Sunday",
				        de_DE: "Sonntag",
				      },
				      "confirmar-fechas": {
				        es_ES: "Confirmar fechas",
				        en_US: "Confirm dates",
				        de_DE: "Termine bestätigen",
				      },
				      "edad-nino": {
				        es_ES: "Edad niño",
				        en_US: "Child age",
				        de_DE: "Alter des Kindes",
				      },
				    };
				  
				  const $e = customDatepicker.be(customDatepicker.ke);

			    var se = {};
			    Object.defineProperty(se, "__esModule", { value: !0 });
			    var de = (se.default = void 0),
			      Ce = {
			        days: [
			          language["Domingo"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Lunes"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Martes"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Jueves"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Viernes"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Sábado"][Liferay.ThemeDisplay.getLanguageId()],
			        ],
			        daysShort: [
			          language["Domingo"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Lunes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Martes"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Jueves"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Viernes"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Sábado"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			        ],
			        daysMin: [
			          language["Domingo"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			          language["Lunes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
			          language["Martes"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			          language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			          language["Jueves"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			          language["Viernes"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			          language["Sábado"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            2
			          ),
			        ],
			        months: [
			          language["Enero"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Febrero"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Marzo"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Abril"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Mayo"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Junio"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Julio"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Agosto"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Septiembre"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Octubre"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Noviembre"][Liferay.ThemeDisplay.getLanguageId()],
			          language["Diciembre"][Liferay.ThemeDisplay.getLanguageId()],
			        ],
			        monthsShort: [
			          language["Enero"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Febrero"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Marzo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Abril"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Mayo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Junio"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Julio"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
			          language["Agosto"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Septiembre"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Octubre"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Noviembre"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			          language["Diciembre"][Liferay.ThemeDisplay.getLanguageId()].substring(
			            0,
			            3
			          ),
			        ],
			        today: "Hoy",
			        clear: "Limpiar",
			        dateFormat: "dd/MM/yyyy",
			        timeFormat: "hh:mm aa",
			        firstDay: 1,
			      };
			    de = se.default = Ce;
			    new $e("#date-range", {
			      classes: "range-picker-reserve",
			      locale: de,
			      range: !0,
			      multipleDatesSeparator: " - ",
			      container: "#reserveCard",
			      autoClose: !1,
			      onRenderCell: function (d, type) {
			        if (d.cellType == "day") {
			          var disabled = false;

			          if (
			            new Date(d.date) < new Date(new Date().valueOf() - 1000 * 3600 * 24)
			          ) {
			            return {
			              disabled: true,
			            };
			          } else {
			            return { disabled: disabled };
			          }
			        }
			      },
			      inline: !1,
			      navTitles: { days: "<strong>MMMM</strong>" },
			      buttons: [
			        {
			          content:
			            language["confirmar-fechas"][Liferay.ThemeDisplay.getLanguageId()],
			          className:
			            "uppercase btn-picker-custom !p-2 text-dark-5 hover:bg-terra-8 hover:text-neutral-0 transition-colors duration-300",
			          onClick: (y) => {
			            y.hide();
			          },
			        },
			      ],
			      toggleSelected: !1,
			      onShow: () => {
			        const y = document.querySelector(".range-picker-reserve"),
			          L = document.getElementById("parentElementoPicker");
			        L &&
			          y &&
			          ((y.style.width = `${L.clientWidth}px`),
			            (y.style.left = `${L.offsetLeft}px`),
			            L.offsetLeft == 8 && (y.style.left = `${L.offsetLeft + 13}px`));
			      },
			    });
				setDatepicker('datepicker');
			};
				
			if(!datepicker)
				getDatepicker();
		}, []);

  /**
   * Función para cerrar el motor de b�squeda
   */
  function openSearch() {
    setIsSearchOpen(true);
  }

  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function closeSearch() {
    setIsSearchOpen(false);
  }

  /**
     * Funci�n que maneja el submit del formulario
     */
  function handleSubmit(e) {
    e.preventDefault();
  }

  // Ocultar el motor de b�squeda al llegar al final de la página
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (!isSearchOpen) {
        if ((window.scrollY + window.innerHeight) >= (document.body.clientHeight - 200)) {
          MotorBusqueda.current?.classList.contains('hidden') ? null : MotorBusqueda.current.classList.add('hidden');
        } else {
          MotorBusqueda.current?.classList.contains('hidden') ? MotorBusqueda.current.classList.remove('hidden') : null;
        }
      }
    })
  })

  function openBookingtravel() {
    window.open('https://bookingtravel.hotelsonjaumell.com/' + urlFlight + "/");
  }
  
  function openMobile() {
	const reserveFrameCard = document.getElementById("reserveFrameCard");
	const reserveFrameCardMobile = document.getElementById("reserveFrameCardMobile");
	let itsOpen = false; // Indica si el panel está abierto
	if (reserveFrameCard?.classList.contains("hidden")) {
	      itsOpen = true;
	      reserveFrameCardMobile.classList.add("brightness-50");

	      reserveFrameCard?.classList.remove("hidden", "animate-fade-out-up");
	      reserveFrameCard.classList.add(
	        "flex",
	        "fixed",
	        "top-0",
	        "mobile-reserve",
	        "animate-fade-in-down"
	      );
	    }
  }


  return (
    <ParametrosBusquedaProvider>
      <div className="fixed bottom-0 !px-6 sm:!px-0 flex flex-col justify-center w-full z-30 transition-all duration-300" id="reserveCard">

        <div className="flex lg:hidden bg-neutral-0 container !mx-auto rounded-lg !my-4 !p-2 gap-4 shadow-2xl" id="reserveFrameCardMobile">
          <a href="#" className="h-14 w-fit !p-3 border border-dark-5 bg-neutral-0 hover:bg-terra-8 rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="serviceCenterReserve" data-astro-cid-ewiz6fue="">
            <div className="iconCall h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
          </a>
          <a href="#" className="h-14 w-fit !p-3 border border-dark-5 bg-neutral-0 hover:bg-terra-8 rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="insideReserve" data-astro-cid-ewiz6fue="">
            <div className="iconAccount h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
          </a>
          <a href="#" className="w-full bg-terra-5 gap-2 hover:bg-terra-8 hover:text-neutral-0 uppercase rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="iconCallReserve" onClick={openMobile} data-astro-cid-ewiz6fue="">
            <div className="iconCalendar h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
            {language["reservar"][Liferay.ThemeDisplay.getLanguageId()]}
          </a>
        </div>

        <div className="hidden lg:flex bg-neutral-0 flex-col right-4 lg:right-auto left-4 lg:left-auto lg:flex-row !w-auto 2xl:!w-[90vw] !mx-auto rounded-lg !my-4 !p-2 gap-4 justify-stretch shadow-8xl lg:shadow-2xl"
          style={{animationDuration: "500ms"}}
          id="reserveFrameCard">
          <div className="flex flex-col items-start lg:items-end gap-2">
            <a href="#"
              className="gap-2 text-link-md text-dark-5 btn-reserve-active btn-reserve-types flex items-center justify-center w-fit button-link-sj no-underline"
              id="hotelReserve" data-astro-cid-ewiz6fue>
              <div className="iconBed h-4 w-4 bg-dark-5" data-astro-cid-ewiz6fue></div>
              <div class="reserve-text-active" data-astro-cid-ewiz6fue>{language["Hotel"][Liferay.ThemeDisplay.getLanguageId()]}</div>
            </a>
            <a href="#" onClick={openBookingtravel}
              className="gap-2 text-link-md text-dark-5 btn-reserve-types flex items-center justify-center w-fit button-link-sj no-underline"
              id="hotelFlightReserve" data-astro-cid-ewiz6fue>
              <div className="iconPlane h-4 w-4 bg-dark-5" data-astro-cid-ewiz6fue></div>
              <div class="reserve-text-active w-full" data-astro-cid-ewiz6fue>{language["Hotel-vuelo"][Liferay.ThemeDisplay.getLanguageId()]}</div>
            </a>
          </div>
          <div className={isSearchOpen ? "flex flex-col lg:flex-row gap-2 grow" : "flex flex-col lg:flex-row gap-2 grow"} ref={MotorBusqueda}>
                {datosEntrada.map((dato) => <InputSearch key={dato.id} isSearchOpen={isSearchOpen} dato={dato} />)}
                <ButtonSubmit submitAction={handleSubmit} />
          </div>
        </div>

      </div>
    </ParametrosBusquedaProvider>
  )
};

class WebComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {

    createRoot(this).render(
      <MotorBusqueda
      />,
      this
    );
  }
}

const ELEMENT_ID = 'liferay-hotel-sonjaumell-motor-reserva';

if (!customElements.get(ELEMENT_ID)) {
  customElements.define(ELEMENT_ID, WebComponent);
}
