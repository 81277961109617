import React from 'react';

export const language = {
    "consultar-disponibilidad": {
		"es_ES": "Consultar disponibilidad",
		"en_US": "Check availability",
		"de_DE": "Verfügbarkeit prüfen"
	},
  "adultos": {
		"es_ES": "Adultos",
		"en_US": "Adults",
		"de_DE": "Erwachsene"
	},
  "ninos": {
		"es_ES": "Niños",
		"en_US": "Kids",
		"de_DE": "Kinder"
	},
  "confirmar-huespedes": {
		"es_ES": "Confirmar huéspedes",
		"en_US": "Confirm guests",
		"de_DE": "Gäste bestätigen"
	},
  "reservar": {
		"es_ES": "Reservar",
		"en_US": "Book",
		"de_DE": "Reservieren"
	},
  "edad-nino": {
		"es_ES": "Edad niño",
		"en_US": "Child age",
		"de_DE": "Alter des Kindes"
	},
  "Hotel": {
		"es_ES": "Hotel",
		"en_US": "Hotel",
		"de_DE": "Hotel"
	},
  "Hotel-vuelo": {
		"es_ES": "Hotel + vuelo",
		"en_US": "Hotel + flight",
		"de_DE": "Hotel + Flug"
	},
	"seleccione-fechas": {
		"es_ES": "Seleccione fechas",
		"en_US": "Select dates",
		"de_DE": "Wählen Sie Daten aus"
	},
	"seleccione-huespedes": {
		"es_ES": "Seleccione huéspedes",
		"en_US": "Select guests",
		"de_DE": "Wählen Sie Gäste aus"
	},
	"codigo-promocional": {
		"es_ES": "Código promocional",
		"en_US": "Promotional code",
		"de_DE": "Promo-code"
	},
	
}