// Dependencias
import React from "react"

// Componentes
import Calendar from "./Calendar";

function Calendario({dropdownName, onFocus}) {
    return (
        	<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3"
                id="date-rangeContainer" onClick={onFocus}>
                <span className="!px-2 iconCalendarContainer" data-id-trigger-calendar="date-range">
                  <a
                    href="#"
                    className="flex items-center justify-center w-fit button-link-sj no-underline"
                    id=""
                    data-astro-cid-ewiz6fue
					onClick={onFocus}
                  >
                    <div
                      className="iconCalendar h-6 w-6 bg-terra-8 duration-300"
                      data-astro-cid-ewiz6fue
                    ></div>
                  </a>
                </span>
            	<Calendar dropdownName={dropdownName} onFocus={onFocus} />
            </div>
    )
}

export default Calendario;