// Dependencias
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { language } from './language.js';

// Contexto
import { ParametrosBusquedaContext } from './parametrosBusqueda';
import { getConfiguracion } from './api';

function ButtonSubmit({ submitAction }) {
    const {
        submitSearchParameters
    } = useContext(ParametrosBusquedaContext);

    /**
     * Handle click on submit button
     * @param {*} e 
     */
    async function handleSubmit(e) {
        submitAction(e);
        var urlRoiback = await getConfiguracion("url-roiback-" + Liferay.ThemeDisplay.getLanguageId());
        submitSearchParameters(urlRoiback);
    }

    return (
        <div className="w-full lg:w-fit">
            <a
                className="!w-full lg:!w-max text-btn-md rounded-lg uppercase !p-4 gap-2 bg-terra-5 text-dark-5 hover:bg-terra-8 hover:text-neutral-0 transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline"
                onClick={(e) => handleSubmit(e)}
                id="btnConsultarDisponibilidad"
                disabled={false}
                data-astro-cid-ewiz6fue
            >
            <div data-astro-cid-ewiz6fue>{language["consultar-disponibilidad"][Liferay.ThemeDisplay.getLanguageId()]}</div>
            <div
              className="iconRightArrow h-4 w-4 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300"
              data-astro-cid-ewiz6fue
            ></div>
            </a>
        </div>
    )
}

ButtonSubmit.propTypes = {
    submitAction: PropTypes.func.isRequired
}

export default ButtonSubmit