var we =
    typeof globalThis < "u"
      ? globalThis
      : typeof window < "u"
        ? window
        : typeof global < "u"
          ? global
          : typeof self < "u"
            ? self
            : {};
  export function be(y) {
    return y &&
      y.__esModule &&
      Object.prototype.hasOwnProperty.call(y, "default")
      ? y.default
      : y;
  }

var le = { exports: {} };
(function (y, L) {
    (function (k, S) {
        y.exports = S();
    })(we, function () {
        return (function () {
            var k = {
                d: function (o, e) {
                    for (var i in e)
                        k.o(e, i) &&
                            !k.o(o, i) &&
                            Object.defineProperty(o, i, { enumerable: !0, get: e[i] });
                },
                o: function (o, e) {
                    return Object.prototype.hasOwnProperty.call(o, e);
                },
            },
                S = {};
            k.d(S, {
                default: function () {
                    return E;
                },
            });
            var l = {
                days: "days",
                months: "months",
                years: "years",
                day: "day",
                month: "month",
                year: "year",
                eventChangeViewDate: "changeViewDate",
                eventChangeCurrentView: "changeCurrentView",
                eventChangeFocusDate: "changeFocusDate",
                eventChangeSelectedDate: "changeSelectedDate",
                eventChangeTime: "changeTime",
                eventChangeLastSelectedDate: "changeLastSelectedDate",
                actionSelectDate: "selectDate",
                actionUnselectDate: "unselectDate",
                cssClassWeekend: "-weekend-",
            },
                ae = {
                    classes: "",
                    inline: !1,
                    locale: {
                        days: [
                            "Воскресенье",
                            "Понедельник",
                            "Вторник",
                            "Среда",
                            "Четверг",
                            "Пятница",
                            "Суббота",
                        ],
                        daysShort: ["Вос", "Пон", "Вто", "Сре", "Чет", "Пят", "Суб"],
                        daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                        months: [
                            "Январь",
                            "Февраль",
                            "Март",
                            "Апрель",
                            "Май",
                            "Июнь",
                            "Июль",
                            "Август",
                            "Сентябрь",
                            "Октябрь",
                            "Ноябрь",
                            "Декабрь",
                        ],
                        monthsShort: [
                            "Янв",
                            "Фев",
                            "Мар",
                            "Апр",
                            "Май",
                            "Июн",
                            "Июл",
                            "Авг",
                            "Сен",
                            "Окт",
                            "Ноя",
                            "Дек",
                        ],
                        today: "Сегодня",
                        clear: "Очистить",
                        dateFormat: "dd.MM.yyyy",
                        timeFormat: "HH:mm",
                        firstDay: 1,
                    },
                    startDate: new Date(),
                    firstDay: "",
                    weekends: [6, 0],
                    dateFormat: "",
                    altField: "",
                    altFieldDateFormat: "T",
                    toggleSelected: !0,
                    keyboardNav: !0,
                    selectedDates: !1,
                    container: "",
                    isMobile: !1,
                    visible: !1,
                    position: "bottom left",
                    offset: 12,
                    view: l.days,
                    minView: l.days,
                    showOtherMonths: !0,
                    selectOtherMonths: !0,
                    moveToOtherMonthsOnSelect: !0,
                    showOtherYears: !0,
                    selectOtherYears: !0,
                    moveToOtherYearsOnSelect: !0,
                    minDate: "",
                    maxDate: "",
                    disableNavWhenOutOfRange: !0,
                    multipleDates: !1,
                    multipleDatesSeparator: ", ",
                    range: !1,
                    dynamicRange: !0,
                    buttons: !1,
                    monthsField: "monthsShort",
                    showEvent: "focus",
                    autoClose: !1,
                    fixedHeight: !1,
                    prevHtml: '<svg><path d="M 17,12 l -5,5 l 5,5"></path></svg>',
                    nextHtml: '<svg><path d="M 14,12 l 5,5 l -5,5"></path></svg>',
                    navTitles: {
                        days: "MMMM, <i>yyyy</i>",
                        months: "yyyy",
                        years: "yyyy1 - yyyy2",
                    },
                    timepicker: !1,
                    onlyTimepicker: !1,
                    dateTimeSeparator: " ",
                    timeFormat: "",
                    minHours: 0,
                    maxHours: 24,
                    minMinutes: 0,
                    maxMinutes: 59,
                    hoursStep: 1,
                    minutesStep: 1,
                    onSelect: !1,
                    onChangeViewDate: !1,
                    onChangeView: !1,
                    onRenderCell: !1,
                    onShow: !1,
                    onHide: !1,
                    onClickDayName: !1,
                };
            function w(o) {
                let e =
                    arguments.length > 1 && arguments[1] !== void 0
                        ? arguments[1]
                        : document;
                return typeof o == "string" ? e.querySelector(o) : o;
            }
            function M() {
                let {
                    tagName: o = "div",
                    className: e = "",
                    innerHtml: i = "",
                    id: t = "",
                    attrs: n = {},
                } = arguments.length > 0 && arguments[0] !== void 0
                        ? arguments[0]
                        : {},
                    a = document.createElement(o);
                return (
                    e && a.classList.add(...e.split(" ")),
                    t && (a.id = t),
                    i && (a.innerHTML = i),
                    n && Z(a, n),
                    a
                );
            }
            function Z(o, e) {
                for (let [i, t] of Object.entries(e))
                    t !== void 0 && o.setAttribute(i, t);
                return o;
            }
            function ne(o) {
                return new Date(o.getFullYear(), o.getMonth() + 1, 0).getDate();
            }
            function v(o) {
                let e = o.getHours(),
                    { hours: i, dayPeriod: t } = re(e);
                return {
                    year: o.getFullYear(),
                    month: o.getMonth(),
                    fullMonth:
                        o.getMonth() + 1 < 10
                            ? "0" + (o.getMonth() + 1)
                            : o.getMonth() + 1,
                    date: o.getDate(),
                    fullDate: o.getDate() < 10 ? "0" + o.getDate() : o.getDate(),
                    day: o.getDay(),
                    hours: e,
                    fullHours: R(e),
                    hours12: i,
                    dayPeriod: t,
                    fullHours12: R(i),
                    minutes: o.getMinutes(),
                    fullMinutes:
                        o.getMinutes() < 10 ? "0" + o.getMinutes() : o.getMinutes(),
                };
            }
            function re(o) {
                return {
                    dayPeriod: o > 11 ? "pm" : "am",
                    hours: o % 12 == 0 ? 12 : o % 12,
                };
            }
            function R(o) {
                return o < 10 ? "0" + o : o;
            }
            function H(o) {
                let e = 10 * Math.floor(o.getFullYear() / 10);
                return [e, e + 9];
            }
            function T() {
                let o = [];
                for (var e = arguments.length, i = new Array(e), t = 0; t < e; t++)
                    i[t] = arguments[t];
                return (
                    i.forEach((n) => {
                        if (typeof n == "object") for (let a in n) n[a] && o.push(a);
                        else n && o.push(n);
                    }),
                    o.join(" ")
                );
            }
            function D(o, e) {
                let i =
                    arguments.length > 2 && arguments[2] !== void 0
                        ? arguments[2]
                        : l.days;
                if (!o || !e) return !1;
                let t = v(o),
                    n = v(e);
                return {
                    [l.days]:
                        t.date === n.date && t.month === n.month && t.year === n.year,
                    [l.months]: t.month === n.month && t.year === n.year,
                    [l.years]: t.year === n.year,
                }[i];
            }
            function F(o, e, i) {
                let t = z(o, !1).getTime(),
                    n = z(e, !1).getTime();
                return i ? t >= n : t > n;
            }
            function K(o, e) {
                return !F(o, e, !0);
            }
            function z(o) {
                let e =
                    !(arguments.length > 1 && arguments[1] !== void 0) ||
                    arguments[1],
                    i = new Date(o.getTime());
                return (
                    typeof e != "boolean" ||
                    e ||
                    (function (t) {
                        t.setHours(0, 0, 0, 0);
                    })(i),
                    i
                );
            }
            function $(o, e, i) {
                o.length
                    ? o.forEach((t) => {
                        t.addEventListener(e, i);
                    })
                    : o.addEventListener(e, i);
            }
            function J(o, e) {
                return (
                    !(!o || o === document || o instanceof DocumentFragment) &&
                    (o.matches(e) ? o : J(o.parentNode, e))
                );
            }
            function U(o, e, i) {
                return o > i ? i : o < e ? e : o;
            }
            function G(o) {
                for (
                    var e = arguments.length, i = new Array(e > 1 ? e - 1 : 0), t = 1;
                    t < e;
                    t++
                )
                    i[t - 1] = arguments[t];
                return (
                    i
                        .filter((n) => n)
                        .forEach((n) => {
                            for (let [a, s] of Object.entries(n))
                                if (s !== void 0 && s.toString() === "[object Object]") {
                                    let r = o[a] !== void 0 ? o[a].toString() : void 0,
                                        h = s.toString(),
                                        d = Array.isArray(s) ? [] : {};
                                    (o[a] = o[a] ? (r !== h ? d : o[a]) : d), G(o[a], s);
                                } else o[a] = s;
                        }),
                    o
                );
            }
            function C(o) {
                let e = o;
                return (
                    o instanceof Date ||
                    (typeof o == "string" &&
                        /^\d{4}-\d{2}-\d{2}$/.test(o) &&
                        (o += "T00:00:00"),
                        (e = new Date(o))),
                    isNaN(e.getTime()) &&
                    (console.log(`Unable to convert value "${o}" to Date object`),
                        (e = !1)),
                    e
                );
            }
            function ee(o) {
                let e = "\\s|\\.|-|/|\\\\|,|\\$|\\!|\\?|:|;";
                return new RegExp("(^|>|" + e + ")(" + o + ")($|<|" + e + ")", "g");
            }
            function A(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            class ce {
                constructor() {
                    let {
                        type: e,
                        date: i,
                        dp: t,
                        opts: n,
                        body: a,
                    } = arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : {};
                    A(this, "focus", () => {
                        this.$cell.classList.add("-focus-"), (this.focused = !0);
                    }),
                        A(this, "removeFocus", () => {
                            this.$cell.classList.remove("-focus-"), (this.focused = !1);
                        }),
                        A(this, "select", () => {
                            this.$cell.classList.add("-selected-"), (this.selected = !0);
                        }),
                        A(this, "removeSelect", () => {
                            this.$cell.classList.remove(
                                "-selected-",
                                "-range-from-",
                                "-range-to-"
                            ),
                                (this.selected = !1);
                        }),
                        A(this, "onChangeSelectedDate", () => {
                            this.isDisabled ||
                                (this._handleSelectedStatus(),
                                    this.opts.range && this._handleRangeStatus());
                        }),
                        A(this, "onChangeFocusDate", (s) => {
                            if (!s) return void (this.focused && this.removeFocus());
                            let r = D(s, this.date, this.type);
                            r ? this.focus() : !r && this.focused && this.removeFocus(),
                                this.opts.range && this._handleRangeStatus();
                        }),
                        A(
                            this,
                            "render",
                            () => (
                                (this.$cell.innerHTML = this._getHtml()),
                                this._handleClasses(),
                                this.$cell
                            )
                        ),
                        (this.type = e),
                        (this.singleType = this.type.slice(0, -1)),
                        (this.date = i),
                        (this.dp = t),
                        (this.opts = n),
                        (this.body = a),
                        (this.customData = !1),
                        this.init();
                }
                init() {
                    var e;
                    let { onRenderCell: i } = this.opts;
                    i &&
                        (this.customData = i({
                            date: this.date,
                            cellType: this.singleType,
                            datepicker: this.dp,
                        })),
                        this._createElement(),
                        this._bindDatepickerEvents(),
                        (e = this.customData) !== null &&
                        e !== void 0 &&
                        e.disabled &&
                        this.dp.disableDate(this.date);
                }
                _bindDatepickerEvents() {
                    this.dp.on(l.eventChangeSelectedDate, this.onChangeSelectedDate),
                        this.dp.on(l.eventChangeFocusDate, this.onChangeFocusDate);
                }
                unbindDatepickerEvents() {
                    this.dp.off(l.eventChangeSelectedDate, this.onChangeSelectedDate),
                        this.dp.off(l.eventChangeFocusDate, this.onChangeFocusDate);
                }
                _createElement() {
                    var e;
                    let { year: i, month: t, date: n } = v(this.date),
                        a =
                            ((e = this.customData) === null || e === void 0
                                ? void 0
                                : e.attrs) || {};
                    (this.$cell = M({
                        attrs: { "data-year": i, "data-month": t, "data-date": n, ...a },
                    })),
                        (this.$cell.adpCell = this);
                }
                _getClassName() {
                    var e;
                    let i = new Date(),
                        { selectOtherMonths: t, selectOtherYears: n } = this.opts,
                        { minDate: a, maxDate: s, isDateDisabled: r } = this.dp,
                        { day: h } = v(this.date),
                        d = this._isOutOfMinMaxRange(),
                        u = r(this.date),
                        c = T("air-datepicker-cell", `-${this.singleType}-`, {
                            "-current-": D(i, this.date, this.type),
                            "-min-date-": a && D(a, this.date, this.type),
                            "-max-date-": s && D(s, this.date, this.type),
                        }),
                        p = "";
                    switch (this.type) {
                        case l.days:
                            p = T({
                                "-weekend-": this.dp.isWeekend(h),
                                "-other-month-": this.isOtherMonth,
                                "-disabled-": (this.isOtherMonth && !t) || d || u,
                            });
                            break;
                        case l.months:
                            p = T({ "-disabled-": d });
                            break;
                        case l.years:
                            p = T({
                                "-other-decade-": this.isOtherDecade,
                                "-disabled-": d || (this.isOtherDecade && !n),
                            });
                    }
                    return T(
                        c,
                        p,
                        (e = this.customData) === null || e === void 0
                            ? void 0
                            : e.classes
                    ).split(" ");
                }
                _getHtml() {
                    var e;
                    let { year: i, month: t, date: n } = v(this.date),
                        { showOtherMonths: a, showOtherYears: s } = this.opts;
                    if ((e = this.customData) !== null && e !== void 0 && e.html)
                        return this.customData.html;
                    switch (this.type) {
                        case l.days:
                            return !a && this.isOtherMonth ? "" : n;
                        case l.months:
                            return this.dp.locale[this.opts.monthsField][t];
                        case l.years:
                            return !s && this.isOtherDecade ? "" : i;
                    }
                }
                _isOutOfMinMaxRange() {
                    let { minDate: e, maxDate: i } = this.dp,
                        { type: t, date: n } = this,
                        { month: a, year: s, date: r } = v(n),
                        h = t === l.days,
                        d = t === l.years,
                        u = !!e && new Date(s, d ? e.getMonth() : a, h ? r : e.getDate()),
                        c = !!i && new Date(s, d ? i.getMonth() : a, h ? r : i.getDate());
                    return e && i
                        ? K(u, e) || F(c, i)
                        : e
                            ? K(u, e)
                            : i
                                ? F(c, i)
                                : void 0;
                }
                destroy() {
                    this.unbindDatepickerEvents();
                }
                _handleRangeStatus() {
                    const {
                        selectedDates: e,
                        focusDate: i,
                        rangeDateTo: t,
                        rangeDateFrom: n,
                    } = this.dp,
                        a = e.length;
                    if (!a) return;
                    let s = n,
                        r = t;
                    if (a === 1 && i) {
                        const p = F(i, e[0]);
                        (s = p ? e[0] : i), (r = p ? i : e[0]);
                    }
                    let h = T({
                        "-in-range-":
                            s &&
                            r &&
                            ((d = this.date), (u = s), (c = r), F(d, u) && K(d, c)),
                        "-range-from-": s && D(this.date, s, this.type),
                        "-range-to-": r && D(this.date, r, this.type),
                    });
                    var d, u, c;
                    this.$cell.classList.remove(
                        "-range-from-",
                        "-range-to-",
                        "-in-range-"
                    ),
                        h && this.$cell.classList.add(...h.split(" "));
                }
                _handleSelectedStatus() {
                    let e = this.dp._checkIfDateIsSelected(this.date, this.type);
                    e ? this.select() : !e && this.selected && this.removeSelect();
                }
                _handleInitialFocusStatus() {
                    D(this.dp.focusDate, this.date, this.type) && this.focus();
                }
                _handleClasses() {
                    this.$cell.setAttribute("class", ""),
                        this._handleInitialFocusStatus(),
                        this.dp.hasSelectedDates &&
                        (this._handleSelectedStatus(),
                            this.dp.opts.range && this._handleRangeStatus()),
                        this.$cell.classList.add(...this._getClassName());
                }
                get isDisabled() {
                    return this.$cell.matches(".-disabled-");
                }
                get isOtherMonth() {
                    return this.dp.isOtherMonth(this.date);
                }
                get isOtherDecade() {
                    return this.dp.isOtherDecade(this.date);
                }
            }
            function _(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            let ue = {
                [l.days]: `<div class="air-datepicker-body--day-names"></div><div class="air-datepicker-body--cells -${l.days}-"></div>`,
                [l.months]: `<div class="air-datepicker-body--cells -${l.months}-"></div>`,
                [l.years]: `<div class="air-datepicker-body--cells -${l.years}-"></div>`,
            };
            const q = ".air-datepicker-cell";
            class x {
                constructor(e) {
                    let { dp: i, type: t, opts: n } = e;
                    _(this, "handleClick", (a) => {
                        let s = a.target.closest(q).adpCell;
                        if (s.isDisabled) return;
                        if (!this.dp.isMinViewReached) return void this.dp.down();
                        let r = this.dp._checkIfDateIsSelected(s.date, s.type);
                        r
                            ? this.dp._handleAlreadySelectedDates(r, s.date)
                            : this.dp.selectDate(s.date);
                    }),
                        _(this, "handleDayNameClick", (a) => {
                            let s = a.target.getAttribute("data-day-index");
                            this.opts.onClickDayName({
                                dayIndex: Number(s),
                                datepicker: this.dp,
                            });
                        }),
                        _(this, "onChangeCurrentView", (a) => {
                            a !== this.type ? this.hide() : (this.show(), this.render());
                        }),
                        _(this, "onMouseOverCell", (a) => {
                            let s = J(a.target, q);
                            this.dp.setFocusDate(!!s && s.adpCell.date);
                        }),
                        _(this, "onMouseOutCell", () => {
                            this.dp.setFocusDate(!1);
                        }),
                        _(this, "onClickBody", (a) => {
                            let { onClickDayName: s } = this.opts,
                                r = a.target;
                            r.closest(q) && this.handleClick(a),
                                s &&
                                r.closest(".air-datepicker-body--day-name") &&
                                this.handleDayNameClick(a);
                        }),
                        _(this, "onMouseDown", (a) => {
                            this.pressed = !0;
                            let s = J(a.target, q),
                                r = s && s.adpCell;
                            D(r.date, this.dp.rangeDateFrom) &&
                                (this.rangeFromFocused = !0),
                                D(r.date, this.dp.rangeDateTo) && (this.rangeToFocused = !0);
                        }),
                        _(this, "onMouseMove", (a) => {
                            if (!this.pressed || !this.dp.isMinViewReached) return;
                            a.preventDefault();
                            let s = J(a.target, q),
                                r = s && s.adpCell,
                                {
                                    selectedDates: h,
                                    rangeDateTo: d,
                                    rangeDateFrom: u,
                                } = this.dp;
                            if (!r || r.isDisabled) return;
                            let { date: c } = r;
                            if (h.length === 2) {
                                if (this.rangeFromFocused && !F(c, d)) {
                                    let { hours: p, minutes: g } = v(u);
                                    c.setHours(p),
                                        c.setMinutes(g),
                                        (this.dp.rangeDateFrom = c),
                                        this.dp.replaceDate(u, c);
                                }
                                if (this.rangeToFocused && !K(c, u)) {
                                    let { hours: p, minutes: g } = v(d);
                                    c.setHours(p),
                                        c.setMinutes(g),
                                        (this.dp.rangeDateTo = c),
                                        this.dp.replaceDate(d, c);
                                }
                            }
                        }),
                        _(this, "onMouseUp", () => {
                            (this.pressed = !1),
                                (this.rangeFromFocused = !1),
                                (this.rangeToFocused = !1);
                        }),
                        _(this, "onChangeViewDate", (a, s) => {
                            if (!this.isVisible) return;
                            let r = H(a),
                                h = H(s);
                            switch (this.dp.currentView) {
                                case l.days:
                                    if (D(a, s, l.months)) return;
                                    break;
                                case l.months:
                                    if (D(a, s, l.years)) return;
                                    break;
                                case l.years:
                                    if (r[0] === h[0] && r[1] === h[1]) return;
                            }
                            this.render();
                        }),
                        _(this, "render", () => {
                            this.destroyCells(),
                                this._generateCells(),
                                this.cells.forEach((a) => {
                                    this.$cells.appendChild(a.render());
                                });
                        }),
                        (this.dp = i),
                        (this.type = t),
                        (this.opts = n),
                        (this.cells = []),
                        (this.$el = ""),
                        (this.pressed = !1),
                        (this.isVisible = !0),
                        this.init();
                }
                init() {
                    this._buildBaseHtml(),
                        this.type === l.days && this.renderDayNames(),
                        this.render(),
                        this._bindEvents(),
                        this._bindDatepickerEvents();
                }
                _bindEvents() {
                    let { range: e, dynamicRange: i } = this.opts;
                    $(this.$el, "mouseover", this.onMouseOverCell),
                        $(this.$el, "mouseout", this.onMouseOutCell),
                        $(this.$el, "click", this.onClickBody),
                        e &&
                        i &&
                        ($(this.$el, "mousedown", this.onMouseDown),
                            $(this.$el, "mousemove", this.onMouseMove),
                            $(window.document, "mouseup", this.onMouseUp));
                }
                _bindDatepickerEvents() {
                    this.dp.on(l.eventChangeViewDate, this.onChangeViewDate),
                        this.dp.on(l.eventChangeCurrentView, this.onChangeCurrentView);
                }
                _buildBaseHtml() {
                    (this.$el = M({
                        className: `air-datepicker-body -${this.type}-`,
                        innerHtml: ue[this.type],
                    })),
                        (this.$names = w(".air-datepicker-body--day-names", this.$el)),
                        (this.$cells = w(".air-datepicker-body--cells", this.$el));
                }
                _getDayNamesHtml() {
                    let e =
                        arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : this.dp.locale.firstDay,
                        i = "",
                        t = this.dp.isWeekend,
                        { onClickDayName: n } = this.opts,
                        a = e,
                        s = 0;
                    for (; s < 7;) {
                        let r = a % 7;
                        (i += `<div class="${T("air-datepicker-body--day-name", {
                            [l.cssClassWeekend]: t(r),
                            "-clickable-": !!n,
                        })}" data-day-index='${r}'>${this.dp.locale.daysMin[r]}</div>`),
                            s++,
                            a++;
                    }
                    return i;
                }
                renderDayNames() {
                    this.$names.innerHTML = this._getDayNamesHtml();
                }
                _generateCell(e) {
                    let { type: i, dp: t, opts: n } = this;
                    return new ce({ type: i, dp: t, opts: n, date: e, body: this });
                }
                _generateCells() {
                    x.getDatesFunction(this.type)(this.dp, (e) => {
                        this.cells.push(this._generateCell(e));
                    });
                }
                show() {
                    (this.isVisible = !0), this.$el.classList.remove("-hidden-");
                }
                hide() {
                    (this.isVisible = !1), this.$el.classList.add("-hidden-");
                }
                destroyCells() {
                    this.cells.forEach((e) => e.destroy()),
                        (this.cells = []),
                        (this.$cells.innerHTML = "");
                }
                destroy() {
                    this.destroyCells(),
                        this.dp.off(l.eventChangeViewDate, this.onChangeViewDate),
                        this.dp.off(l.eventChangeCurrentView, this.onChangeCurrentView);
                }
                static getDaysDates(e, i) {
                    let {
                        viewDate: t,
                        opts: { fixedHeight: n },
                        locale: { firstDay: a },
                    } = e,
                        s = ne(t),
                        { year: r, month: h } = v(t),
                        d = new Date(r, h, 1),
                        u = new Date(r, h, s),
                        c = d.getDay() - a,
                        p = 6 - u.getDay() + a;
                    (c = c < 0 ? c + 7 : c), (p = p > 6 ? p - 7 : p);
                    let g = (function (B, ie) {
                        let { year: Q, month: De, date: fe } = v(B);
                        return new Date(Q, De, fe - ie);
                    })(d, c),
                        b = s + c + p,
                        f = g.getDate(),
                        { year: X, month: W } = v(g),
                        P = 0;
                    n && (b = 42);
                    const j = [];
                    for (; P < b;) {
                        let B = new Date(X, W, f + P);
                        i && i(B), j.push(B), P++;
                    }
                    return j;
                }
                static getMonthsDates(e, i) {
                    let { year: t } = e.parsedViewDate,
                        n = 0,
                        a = [];
                    for (; n < 12;) {
                        const s = new Date(t, n);
                        a.push(s), i && i(s), n++;
                    }
                    return a;
                }
                static getYearsDates(e, i) {
                    let t = H(e.viewDate),
                        n = t[0] - 1,
                        a = t[1] + 1,
                        s = n,
                        r = [];
                    for (; s <= a;) {
                        const h = new Date(s, 0);
                        r.push(h), i && i(h), s++;
                    }
                    return r;
                }
                static getDatesFunction() {
                    let e =
                        arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : l.days;
                    return {
                        [l.days]: x.getDaysDates,
                        [l.months]: x.getMonthsDates,
                        [l.years]: x.getYearsDates,
                    }[e];
                }
            }
            function O(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            class pe {
                constructor(e) {
                    let { dp: i, opts: t } = e;
                    O(this, "onClickNav", (n) => {
                        let a = J(n.target, ".air-datepicker-nav--action");
                        if (!a) return;
                        let s = a.dataset.action;
                        this.dp[s]();
                    }),
                        O(this, "onChangeViewDate", () => {
                            this.render(), this._resetNavStatus(), this.handleNavStatus();
                        }),
                        O(this, "onChangeCurrentView", () => {
                            this.render(), this._resetNavStatus(), this.handleNavStatus();
                        }),
                        O(this, "onClickNavTitle", () => {
                            this.dp.isFinalView || this.dp.up();
                        }),
                        O(this, "update", () => {
                            let { prevHtml: n, nextHtml: a } = this.opts;
                            (this.$prev.innerHTML = n),
                                (this.$next.innerHTML = a),
                                this._resetNavStatus(),
                                this.render(),
                                this.handleNavStatus();
                        }),
                        O(this, "renderDelay", () => {
                            setTimeout(this.render);
                        }),
                        O(this, "render", () => {
                            (this.$title.innerHTML = this._getTitle()),
                                (function (n, a) {
                                    for (let s in a)
                                        a[s] ? n.classList.add(s) : n.classList.remove(s);
                                })(this.$title, { "-disabled-": this.dp.isFinalView });
                        }),
                        (this.dp = i),
                        (this.opts = t),
                        this.init();
                }
                init() {
                    this._createElement(),
                        this._buildBaseHtml(),
                        this._defineDOM(),
                        this.render(),
                        this.handleNavStatus(),
                        this._bindEvents(),
                        this._bindDatepickerEvents();
                }
                _defineDOM() {
                    (this.$title = w(".air-datepicker-nav--title", this.$el)),
                        (this.$prev = w('[data-action="prev"]', this.$el)),
                        (this.$next = w('[data-action="next"]', this.$el));
                }
                _bindEvents() {
                    this.$el.addEventListener("click", this.onClickNav),
                        this.$title.addEventListener("click", this.onClickNavTitle);
                }
                _bindDatepickerEvents() {
                    this.dp.on(l.eventChangeViewDate, this.onChangeViewDate),
                        this.dp.on(l.eventChangeCurrentView, this.onChangeCurrentView),
                        this.isNavIsFunction &&
                        (this.dp.on(l.eventChangeSelectedDate, this.renderDelay),
                            this.dp.opts.timepicker &&
                            this.dp.on(l.eventChangeTime, this.render));
                }
                destroy() {
                    this.dp.off(l.eventChangeViewDate, this.onChangeViewDate),
                        this.dp.off(l.eventChangeCurrentView, this.onChangeCurrentView),
                        this.isNavIsFunction &&
                        (this.dp.off(l.eventChangeSelectedDate, this.renderDelay),
                            this.dp.opts.timepicker &&
                            this.dp.off(l.eventChangeTime, this.render));
                }
                _createElement() {
                    this.$el = M({ tagName: "nav", className: "air-datepicker-nav" });
                }
                _getTitle() {
                    let { dp: e, opts: i } = this,
                        t = i.navTitles[e.currentView];
                    return typeof t == "function" ? t(e) : e.formatDate(e.viewDate, t);
                }
                handleNavStatus() {
                    let { disableNavWhenOutOfRange: e } = this.opts,
                        { minDate: i, maxDate: t } = this.dp;
                    if ((!i && !t) || !e) return;
                    let { year: n, month: a } = this.dp.parsedViewDate,
                        s = !!i && v(i),
                        r = !!t && v(t);
                    switch (this.dp.currentView) {
                        case l.days:
                            i && s.month >= a && s.year >= n && this._disableNav("prev"),
                                t && r.month <= a && r.year <= n && this._disableNav("next");
                            break;
                        case l.months:
                            i && s.year >= n && this._disableNav("prev"),
                                t && r.year <= n && this._disableNav("next");
                            break;
                        case l.years: {
                            let h = H(this.dp.viewDate);
                            i && s.year >= h[0] && this._disableNav("prev"),
                                t && r.year <= h[1] && this._disableNav("next");
                            break;
                        }
                    }
                }
                _disableNav(e) {
                    w('[data-action="' + e + '"]', this.$el).classList.add(
                        "-disabled-"
                    );
                }
                _resetNavStatus() {
                    (function (e) {
                        for (
                            var i = arguments.length,
                            t = new Array(i > 1 ? i - 1 : 0),
                            n = 1;
                            n < i;
                            n++
                        )
                            t[n - 1] = arguments[n];
                        e.length
                            ? e.forEach((a) => {
                                a.classList.remove(...t);
                            })
                            : e.classList.remove(...t);
                    })(
                        this.$el.querySelectorAll(".air-datepicker-nav--action"),
                        "-disabled-"
                    );
                }
                _buildBaseHtml() {
                    let { prevHtml: e, nextHtml: i } = this.opts;
                    this.$el.innerHTML = `<div class="air-datepicker-nav--action" data-action="prev">${e}</div><div class="air-datepicker-nav--title"></div><div class="air-datepicker-nav--action" data-action="next">${i}</div>`;
                }
                get isNavIsFunction() {
                    let { navTitles: e } = this.opts;
                    return Object.keys(e).find((i) => typeof e[i] == "function");
                }
            }
            var oe = {
                today: {
                    content: (o) => o.locale.today,
                    onClick: (o) => o.setViewDate(new Date()),
                },
                clear: { content: (o) => o.locale.clear, onClick: (o) => o.clear() },
            };
            class me {
                constructor(e) {
                    let { dp: i, opts: t } = e;
                    (this.dp = i), (this.opts = t), this.init();
                }
                init() {
                    this.createElement(), this.render();
                }
                createElement() {
                    this.$el = M({ className: "air-datepicker-buttons" });
                }
                destroy() {
                    this.$el.parentNode.removeChild(this.$el);
                }
                clearHtml() {
                    return (this.$el.innerHTML = ""), this;
                }
                generateButtons() {
                    let { buttons: e } = this.opts;
                    Array.isArray(e) || (e = [e]),
                        e.forEach((i) => {
                            let t = i;
                            typeof i == "string" && oe[i] && (t = oe[i]);
                            let n = this.createButton(t);
                            t.onClick && this.attachEventToButton(n, t.onClick),
                                this.$el.appendChild(n);
                        });
                }
                attachEventToButton(e, i) {
                    e.addEventListener("click", () => {
                        i(this.dp);
                    });
                }
                createButton(e) {
                    let {
                        content: i,
                        className: t,
                        tagName: n = "button",
                        attrs: a = {},
                    } = e;
                    return M({
                        tagName: n,
                        innerHtml: `<span tabindex='-1'>${typeof i == "function" ? i(this.dp) : i
                            }</span>`,
                        className: T("air-datepicker-button", t),
                        attrs: a,
                    });
                }
                render() {
                    this.generateButtons();
                }
            }
            function N(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            class ge {
                constructor() {
                    let { opts: e, dp: i } =
                        arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : {};
                    N(this, "toggleTimepickerIsActive", (n) => {
                        this.dp.timepickerIsActive = n;
                    }),
                        N(this, "onChangeSelectedDate", (n) => {
                            let { date: a, updateTime: s = !1 } = n;
                            a &&
                                (this.setMinMaxTime(a),
                                    this.setCurrentTime(!!s && a),
                                    this.addTimeToDate(a));
                        }),
                        N(this, "onChangeLastSelectedDate", (n) => {
                            n && (this.setTime(n), this.render());
                        }),
                        N(this, "onChangeInputRange", (n) => {
                            let a = n.target;
                            (this[a.getAttribute("name")] = a.value),
                                this.updateText(),
                                this.dp.trigger(l.eventChangeTime, {
                                    hours: this.hours,
                                    minutes: this.minutes,
                                });
                        }),
                        N(this, "onMouseEnterLeave", (n) => {
                            let a = n.target.getAttribute("name"),
                                s = this.$minutesText;
                            a === "hours" && (s = this.$hoursText),
                                s.classList.toggle("-focus-");
                        }),
                        N(this, "onFocus", () => {
                            this.toggleTimepickerIsActive(!0);
                        }),
                        N(this, "onBlur", () => {
                            this.toggleTimepickerIsActive(!1);
                        }),
                        (this.opts = e),
                        (this.dp = i);
                    let { timeFormat: t } = this.dp.locale;
                    t && (t.match(ee("h")) || t.match(ee("hh"))) && (this.ampm = !0),
                        this.init();
                }
                init() {
                    this.setTime(this.dp.lastSelectedDate || this.dp.viewDate),
                        this.createElement(),
                        this.buildHtml(),
                        this.defineDOM(),
                        this.render(),
                        this.bindDatepickerEvents(),
                        this.bindDOMEvents();
                }
                bindDatepickerEvents() {
                    this.dp.on(l.eventChangeSelectedDate, this.onChangeSelectedDate),
                        this.dp.on(
                            l.eventChangeLastSelectedDate,
                            this.onChangeLastSelectedDate
                        );
                }
                bindDOMEvents() {
                    let e = "input";
                    navigator.userAgent.match(/trident/gi) && (e = "change"),
                        $(this.$ranges, e, this.onChangeInputRange),
                        $(this.$ranges, "mouseenter", this.onMouseEnterLeave),
                        $(this.$ranges, "mouseleave", this.onMouseEnterLeave),
                        $(this.$ranges, "focus", this.onFocus),
                        $(this.$ranges, "mousedown", this.onFocus),
                        $(this.$ranges, "blur", this.onBlur);
                }
                createElement() {
                    this.$el = M({
                        className: T("air-datepicker-time", { "-am-pm-": this.dp.ampm }),
                    });
                }
                destroy() {
                    this.dp.off(l.eventChangeSelectedDate, this.onChangeSelectedDate),
                        this.dp.off(
                            l.eventChangeLastSelectedDate,
                            this.onChangeLastSelectedDate
                        ),
                        this.$el.parentNode.removeChild(this.$el);
                }
                buildHtml() {
                    let {
                        ampm: e,
                        hours: i,
                        displayHours: t,
                        minutes: n,
                        minHours: a,
                        minMinutes: s,
                        maxHours: r,
                        maxMinutes: h,
                        dayPeriod: d,
                        opts: { hoursStep: u, minutesStep: c },
                    } = this;
                    this.$el.innerHTML =
                        `<div class="air-datepicker-time--current">   <span class="air-datepicker-time--current-hours">${R(
                            t
                        )}</span>   <span class="air-datepicker-time--current-colon">:</span>   <span class="air-datepicker-time--current-minutes">${R(
                            n
                        )}</span>   ` +
                        (e
                            ? `<span class='air-datepicker-time--current-ampm'>${d}</span>`
                            : "") +
                        `</div><div class="air-datepicker-time--sliders">   <div class="air-datepicker-time--row">      <input type="range" name="hours" value="${i}" min="${a}" max="${r}" step="${u}"/>   </div>   <div class="air-datepicker-time--row">      <input type="range" name="minutes" value="${n}" min="${s}" max="${h}" step="${c}"/>   </div></div>`;
                }
                defineDOM() {
                    let e = (i) => w(i, this.$el);
                    (this.$ranges = this.$el.querySelectorAll('[type="range"]')),
                        (this.$hours = e('[name="hours"]')),
                        (this.$minutes = e('[name="minutes"]')),
                        (this.$hoursText = e(".air-datepicker-time--current-hours")),
                        (this.$minutesText = e(".air-datepicker-time--current-minutes")),
                        (this.$ampm = e(".air-datepicker-time--current-ampm"));
                }
                setTime(e) {
                    this.setMinMaxTime(e), this.setCurrentTime(e);
                }
                addTimeToDate(e) {
                    e && (e.setHours(this.hours), e.setMinutes(this.minutes));
                }
                setMinMaxTime(e) {
                    if ((this.setMinMaxTimeFromOptions(), e)) {
                        let { minDate: i, maxDate: t } = this.dp;
                        i && D(e, i) && this.setMinTimeFromMinDate(i),
                            t && D(e, t) && this.setMaxTimeFromMaxDate(t);
                    }
                }
                setCurrentTime(e) {
                    let { hours: i, minutes: t } = e ? v(e) : this;
                    (this.hours = U(i, this.minHours, this.maxHours)),
                        (this.minutes = U(t, this.minMinutes, this.maxMinutes));
                }
                setMinMaxTimeFromOptions() {
                    let {
                        minHours: e,
                        minMinutes: i,
                        maxHours: t,
                        maxMinutes: n,
                    } = this.opts;
                    (this.minHours = U(e, 0, 23)),
                        (this.minMinutes = U(i, 0, 59)),
                        (this.maxHours = U(t, 0, 23)),
                        (this.maxMinutes = U(n, 0, 59));
                }
                setMinTimeFromMinDate(e) {
                    let { lastSelectedDate: i } = this.dp;
                    (this.minHours = e.getHours()),
                        i && i.getHours() > e.getHours()
                            ? (this.minMinutes = this.opts.minMinutes)
                            : (this.minMinutes = e.getMinutes());
                }
                setMaxTimeFromMaxDate(e) {
                    let { lastSelectedDate: i } = this.dp;
                    (this.maxHours = e.getHours()),
                        i && i.getHours() < e.getHours()
                            ? (this.maxMinutes = this.opts.maxMinutes)
                            : (this.maxMinutes = e.getMinutes());
                }
                updateSliders() {
                    (Z(this.$hours, { min: this.minHours, max: this.maxHours }).value =
                        this.hours),
                        (Z(this.$minutes, {
                            min: this.minMinutes,
                            max: this.maxMinutes,
                        }).value = this.minutes);
                }
                updateText() {
                    (this.$hoursText.innerHTML = R(this.displayHours)),
                        (this.$minutesText.innerHTML = R(this.minutes)),
                        this.ampm && (this.$ampm.innerHTML = this.dayPeriod);
                }
                set hours(e) {
                    this._hours = e;
                    let { hours: i, dayPeriod: t } = re(e);
                    (this.displayHours = this.ampm ? i : e), (this.dayPeriod = t);
                }
                get hours() {
                    return this._hours;
                }
                render() {
                    this.updateSliders(), this.updateText();
                }
            }
            function I(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            class ve {
                constructor(e) {
                    let { dp: i, opts: t } = e;
                    I(this, "pressedKeys", new Set()),
                        I(
                            this,
                            "hotKeys",
                            new Map([
                                [
                                    [
                                        ["Control", "ArrowRight"],
                                        ["Control", "ArrowUp"],
                                    ],
                                    (n) => n.month++,
                                ],
                                [
                                    [
                                        ["Control", "ArrowLeft"],
                                        ["Control", "ArrowDown"],
                                    ],
                                    (n) => n.month--,
                                ],
                                [
                                    [
                                        ["Shift", "ArrowRight"],
                                        ["Shift", "ArrowUp"],
                                    ],
                                    (n) => n.year++,
                                ],
                                [
                                    [
                                        ["Shift", "ArrowLeft"],
                                        ["Shift", "ArrowDown"],
                                    ],
                                    (n) => n.year--,
                                ],
                                [
                                    [
                                        ["Alt", "ArrowRight"],
                                        ["Alt", "ArrowUp"],
                                    ],
                                    (n) => (n.year += 10),
                                ],
                                [
                                    [
                                        ["Alt", "ArrowLeft"],
                                        ["Alt", "ArrowDown"],
                                    ],
                                    (n) => (n.year -= 10),
                                ],
                                [["Control", "Shift", "ArrowUp"], (n, a) => a.up()],
                            ])
                        ),
                        I(this, "handleHotKey", (n) => {
                            let a = this.hotKeys.get(n),
                                s = v(this.getInitialFocusDate());
                            a(s, this.dp);
                            let { year: r, month: h, date: d } = s,
                                u = ne(new Date(r, h));
                            u < d && (d = u);
                            let c = this.dp.getClampedDate(new Date(r, h, d));
                            this.dp.setFocusDate(c, { viewDateTransition: !0 });
                        }),
                        I(this, "isHotKeyPressed", () => {
                            let n = !1,
                                a = this.pressedKeys.size,
                                s = (r) => this.pressedKeys.has(r);
                            for (let [r] of this.hotKeys) {
                                if (n) break;
                                if (Array.isArray(r[0]))
                                    r.forEach((h) => {
                                        n || a !== h.length || (n = h.every(s) && r);
                                    });
                                else {
                                    if (a !== r.length) continue;
                                    n = r.every(s) && r;
                                }
                            }
                            return n;
                        }),
                        I(this, "isArrow", (n) => n >= 37 && n <= 40),
                        I(this, "onKeyDown", (n) => {
                            let { key: a, which: s } = n,
                                {
                                    dp: r,
                                    dp: { focusDate: h },
                                    opts: d,
                                } = this;
                            this.registerKey(a);
                            let u = this.isHotKeyPressed();
                            if (u) return n.preventDefault(), void this.handleHotKey(u);
                            if (this.isArrow(s))
                                return n.preventDefault(), void this.focusNextCell(a);
                            if (a === "Enter") {
                                if (r.currentView !== d.minView) return void r.down();
                                if (h) {
                                    let c = r._checkIfDateIsSelected(h);
                                    return void (c
                                        ? r._handleAlreadySelectedDates(c, h)
                                        : r.selectDate(h));
                                }
                            }
                            a === "Escape" && this.dp.hide();
                        }),
                        I(this, "onKeyUp", (n) => {
                            this.removeKey(n.key);
                        }),
                        (this.dp = i),
                        (this.opts = t),
                        this.init();
                }
                init() {
                    this.bindKeyboardEvents();
                }
                bindKeyboardEvents() {
                    let { $el: e } = this.dp;
                    e.addEventListener("keydown", this.onKeyDown),
                        e.addEventListener("keyup", this.onKeyUp);
                }
                destroy() {
                    let { $el: e } = this.dp;
                    e.removeEventListener("keydown", this.onKeyDown),
                        e.removeEventListener("keyup", this.onKeyUp),
                        (this.hotKeys = null),
                        (this.pressedKeys = null);
                }
                getInitialFocusDate() {
                    let {
                        focusDate: e,
                        currentView: i,
                        selectedDates: t,
                        parsedViewDate: { year: n, month: a },
                    } = this.dp,
                        s = e || t[t.length - 1];
                    if (!s)
                        switch (i) {
                            case l.days:
                                s = new Date(n, a, new Date().getDate());
                                break;
                            case l.months:
                                s = new Date(n, a, 1);
                                break;
                            case l.years:
                                s = new Date(n, 0, 1);
                        }
                    return s;
                }
                focusNextCell(e) {
                    let i = this.getInitialFocusDate(),
                        { currentView: t } = this.dp,
                        { days: n, months: a, years: s } = l,
                        r = v(i),
                        h = r.year,
                        d = r.month,
                        u = r.date;
                    switch (e) {
                        case "ArrowLeft":
                            t === n && (u -= 1), t === a && (d -= 1), t === s && (h -= 1);
                            break;
                        case "ArrowUp":
                            t === n && (u -= 7), t === a && (d -= 3), t === s && (h -= 4);
                            break;
                        case "ArrowRight":
                            t === n && (u += 1), t === a && (d += 1), t === s && (h += 1);
                            break;
                        case "ArrowDown":
                            t === n && (u += 7), t === a && (d += 3), t === s && (h += 4);
                    }
                    let c = this.dp.getClampedDate(new Date(h, d, u));
                    this.dp.setFocusDate(c, { viewDateTransition: !0 });
                }
                registerKey(e) {
                    this.pressedKeys.add(e);
                }
                removeKey(e) {
                    this.pressedKeys.delete(e);
                }
            }
            let ye = {
                on(o, e) {
                    this.__events || (this.__events = {}),
                        this.__events[o]
                            ? this.__events[o].push(e)
                            : (this.__events[o] = [e]);
                },
                off(o, e) {
                    this.__events &&
                        this.__events[o] &&
                        (this.__events[o] = this.__events[o].filter((i) => i !== e));
                },
                removeAllEvents() {
                    this.__events = {};
                },
                trigger(o) {
                    for (
                        var e = arguments.length, i = new Array(e > 1 ? e - 1 : 0), t = 1;
                        t < e;
                        t++
                    )
                        i[t - 1] = arguments[t];
                    this.__events &&
                        this.__events[o] &&
                        this.__events[o].forEach((n) => {
                            n(...i);
                        });
                },
            };
            function m(o, e, i) {
                return (
                    (e = (function (t) {
                        var n = (function (a, s) {
                            if (typeof a != "object" || a === null) return a;
                            var r = a[Symbol.toPrimitive];
                            if (r !== void 0) {
                                var h = r.call(a, "string");
                                if (typeof h != "object") return h;
                                throw new TypeError(
                                    "@@toPrimitive must return a primitive value."
                                );
                            }
                            return String(a);
                        })(t);
                        return typeof n == "symbol" ? n : String(n);
                    })(e)) in o
                        ? Object.defineProperty(o, e, {
                            value: i,
                            enumerable: !0,
                            configurable: !0,
                            writable: !0,
                        })
                        : (o[e] = i),
                    o
                );
            }
            let Y = "",
                V = "",
                te = !1;
            class E {
                static buildGlobalContainer(e) {
                    (te = !0),
                        (Y = M({ className: e, id: e })),
                        w("body").appendChild(Y);
                }
                constructor(e, i) {
                    var t = this;
                    if (
                        (m(this, "viewIndexes", [l.days, l.months, l.years]),
                            m(this, "next", () => {
                                let { year: s, month: r } = this.parsedViewDate;
                                switch (this.currentView) {
                                    case l.days:
                                        this.setViewDate(new Date(s, r + 1, 1));
                                        break;
                                    case l.months:
                                        this.setViewDate(new Date(s + 1, r, 1));
                                        break;
                                    case l.years:
                                        this.setViewDate(new Date(s + 10, 0, 1));
                                }
                            }),
                            m(this, "prev", () => {
                                let { year: s, month: r } = this.parsedViewDate;
                                switch (this.currentView) {
                                    case l.days:
                                        this.setViewDate(new Date(s, r - 1, 1));
                                        break;
                                    case l.months:
                                        this.setViewDate(new Date(s - 1, r, 1));
                                        break;
                                    case l.years:
                                        this.setViewDate(new Date(s - 10, 0, 1));
                                }
                            }),
                            m(this, "_finishHide", () => {
                                (this.hideAnimation = !1),
                                    this._destroyComponents(),
                                    this.$container.removeChild(this.$datepicker);
                            }),
                            m(this, "setPosition", function (s) {
                                let r =
                                    arguments.length > 1 &&
                                    arguments[1] !== void 0 &&
                                    arguments[1];
                                if (typeof (s = s || t.opts.position) == "function")
                                    return void (t.customHide = s({
                                        $datepicker: t.$datepicker,
                                        $target: t.$el,
                                        $pointer: t.$pointer,
                                        isViewChange: r,
                                        done: t._finishHide,
                                    }));
                                let h,
                                    d,
                                    { isMobile: u } = t.opts,
                                    c = t.$el.getBoundingClientRect(),
                                    p = t.$el.getBoundingClientRect(),
                                    g = t.$datepicker.offsetParent,
                                    b = t.$el.offsetParent,
                                    f = t.$datepicker.getBoundingClientRect(),
                                    X = s.split(" "),
                                    W = window.scrollY,
                                    P = window.scrollX,
                                    j = t.opts.offset,
                                    B = X[0],
                                    ie = X[1];
                                if (u) t.$datepicker.style.cssText = "left: 50%; top: 50%";
                                else {
                                    if (
                                        (g === b &&
                                            g !== document.body &&
                                            ((p = {
                                                top: t.$el.offsetTop,
                                                left: t.$el.offsetLeft,
                                                width: c.width,
                                                height: t.$el.offsetHeight,
                                            }),
                                                (W = 0),
                                                (P = 0)),
                                            g !== b && g !== document.body)
                                    ) {
                                        let Q = g.getBoundingClientRect();
                                        (p = {
                                            top: c.top - Q.top,
                                            left: c.left - Q.left,
                                            width: c.width,
                                            height: c.height,
                                        }),
                                            (W = 0),
                                            (P = 0);
                                    }
                                    switch (B) {
                                        case "top":
                                            h = p.top - f.height - j;
                                            break;
                                        case "right":
                                            d = p.left + p.width + j;
                                            break;
                                        case "bottom":
                                            h = p.top + p.height + j;
                                            break;
                                        case "left":
                                            d = p.left - f.width - j;
                                    }
                                    switch (ie) {
                                        case "top":
                                            h = p.top;
                                            break;
                                        case "right":
                                            d = p.left + p.width - f.width;
                                            break;
                                        case "bottom":
                                            h = p.top + p.height - f.height;
                                            break;
                                        case "left":
                                            d = p.left;
                                            break;
                                        case "center":
                                            /left|right/.test(B)
                                                ? (h = p.top + p.height / 2 - f.height / 2)
                                                : (d = p.left + p.width / 2 - f.width / 2);
                                    }
                                    t.$datepicker.style.cssText = `left: ${d + P}px; top: ${h + W
                                        }px`;
                                }
                            }),
                            m(this, "_setInputValue", () => {
                                let {
                                    opts: s,
                                    $altField: r,
                                    locale: { dateFormat: h },
                                } = this,
                                    { altFieldDateFormat: d, altField: u } = s;
                                u && r && (r.value = this._getInputValue(d)),
                                    (this.$el.value = this._getInputValue(h));
                            }),
                            m(this, "_getInputValue", (s) => {
                                let { selectedDates: r, opts: h } = this,
                                    { multipleDates: d, multipleDatesSeparator: u } = h;
                                if (!r.length) return "";
                                let c = typeof s == "function",
                                    p = c ? s(d ? r : r[0]) : r.map((g) => this.formatDate(g, s));
                                return (p = c ? p : p.join(u)), p;
                            }),
                            m(this, "_checkIfDateIsSelected", function (s) {
                                let r =
                                    arguments.length > 1 && arguments[1] !== void 0
                                        ? arguments[1]
                                        : l.days,
                                    h = !1;
                                return (
                                    t.selectedDates.some((d) => {
                                        let u = D(s, d, r);
                                        return (h = u && d), u;
                                    }),
                                    h
                                );
                            }),
                            m(this, "_scheduleCallAfterTransition", (s) => {
                                this._cancelScheduledCall(),
                                    s && s(!1),
                                    (this._onTransitionEnd = () => {
                                        s && s(!0);
                                    }),
                                    this.$datepicker.addEventListener(
                                        "transitionend",
                                        this._onTransitionEnd,
                                        { once: !0 }
                                    );
                            }),
                            m(this, "_cancelScheduledCall", () => {
                                this.$datepicker.removeEventListener(
                                    "transitionend",
                                    this._onTransitionEnd
                                );
                            }),
                            m(this, "setViewDate", (s) => {
                                if (!((s = C(s)) instanceof Date) || D(s, this.viewDate))
                                    return;
                                let r = this.viewDate;
                                this.viewDate = s;
                                let { onChangeViewDate: h } = this.opts;
                                if (h) {
                                    let { month: d, year: u } = this.parsedViewDate;
                                    h({ month: d, year: u, decade: this.curDecade });
                                }
                                this.trigger(l.eventChangeViewDate, s, r);
                            }),
                            m(this, "setFocusDate", function (s) {
                                let r =
                                    arguments.length > 1 && arguments[1] !== void 0
                                        ? arguments[1]
                                        : {};
                                (!s || (s = C(s)) instanceof Date) &&
                                    ((t.focusDate = s), t.trigger(l.eventChangeFocusDate, s, r));
                            }),
                            m(this, "setCurrentView", function (s) {
                                let r =
                                    arguments.length > 1 && arguments[1] !== void 0
                                        ? arguments[1]
                                        : {};
                                if (t.viewIndexes.includes(s)) {
                                    if (
                                        ((t.currentView = s),
                                            t.elIsInput && t.visible && t.setPosition(void 0, !0),
                                            t.trigger(l.eventChangeCurrentView, s),
                                            !t.views[s])
                                    ) {
                                        let h = (t.views[s] = new x({
                                            dp: t,
                                            opts: t.opts,
                                            type: s,
                                        }));
                                        t.shouldUpdateDOM && t.$content.appendChild(h.$el);
                                    }
                                    t.opts.onChangeView && !r.silent && t.opts.onChangeView(s);
                                }
                            }),
                            m(this, "_updateLastSelectedDate", (s) => {
                                (this.lastSelectedDate = s),
                                    this.trigger(l.eventChangeLastSelectedDate, s);
                            }),
                            m(this, "destroy", () => {
                                if (this.isDestroyed) return;
                                let { showEvent: s, isMobile: r } = this.opts,
                                    h = this.$datepicker.parentNode;
                                h && h.removeChild(this.$datepicker),
                                    this.$el.removeEventListener(s, this._onFocus),
                                    this.$el.removeEventListener("blur", this._onBlur),
                                    window.removeEventListener("resize", this._onResize),
                                    r && this._removeMobileAttributes(),
                                    this.keyboardNav && this.keyboardNav.destroy(),
                                    (this.views = null),
                                    (this.nav = null),
                                    (this.$datepicker = null),
                                    (this.opts = {}),
                                    (this.$customContainer = null),
                                    (this.viewDate = null),
                                    (this.focusDate = null),
                                    (this.selectedDates = []),
                                    (this.rangeDateFrom = null),
                                    (this.rangeDateTo = null),
                                    (this.isDestroyed = !0);
                            }),
                            m(this, "update", function () {
                                let s =
                                    arguments.length > 0 && arguments[0] !== void 0
                                        ? arguments[0]
                                        : {},
                                    r =
                                        arguments.length > 1 && arguments[1] !== void 0
                                            ? arguments[1]
                                            : {},
                                    h = G({}, t.opts),
                                    { silent: d } = r;
                                G(t.opts, s);
                                let {
                                    timepicker: u,
                                    buttons: c,
                                    range: p,
                                    selectedDates: g,
                                    isMobile: b,
                                } = t.opts,
                                    f = t.visible || t.treatAsInline;
                                t._createMinMaxDates(),
                                    t._limitViewDateByMaxMinDates(),
                                    t._handleLocale(),
                                    g && ((t.selectedDates = []), t.selectDate(g, { silent: d })),
                                    s.view && t.setCurrentView(s.view, { silent: d }),
                                    t._setInputValue(),
                                    h.range && !p
                                        ? ((t.rangeDateTo = !1), (t.rangeDateFrom = !1))
                                        : !h.range &&
                                        p &&
                                        t.selectedDates.length &&
                                        ((t.rangeDateFrom = t.selectedDates[0]),
                                            (t.rangeDateTo = t.selectedDates[1])),
                                    h.timepicker && !u
                                        ? (f && t.timepicker.destroy(),
                                            (t.timepicker = !1),
                                            t.$timepicker.parentNode.removeChild(t.$timepicker))
                                        : !h.timepicker && u && t._addTimepicker(),
                                    !h.buttons && c
                                        ? t._addButtons()
                                        : h.buttons && !c
                                            ? (t.buttons.destroy(),
                                                t.$buttons.parentNode.removeChild(t.$buttons))
                                            : f && h.buttons && c && t.buttons.clearHtml().render(),
                                    !h.isMobile && b
                                        ? (t.treatAsInline || V || t._createMobileOverlay(),
                                            t._addMobileAttributes(),
                                            t.visible && t._showMobileOverlay())
                                        : h.isMobile &&
                                        !b &&
                                        (t._removeMobileAttributes(),
                                            t.visible &&
                                            (V.classList.remove("-active-"),
                                                typeof t.opts.position != "function" &&
                                                t.setPosition())),
                                    f &&
                                    (t.nav.update(),
                                        t.views[t.currentView].render(),
                                        t.currentView === l.days &&
                                        t.views[t.currentView].renderDayNames());
                            }),
                            m(this, "disableDate", (s, r) => {
                                (Array.isArray(s) ? s : [s]).forEach((h) => {
                                    let d = C(h);
                                    if (!d) return;
                                    let u = r ? "delete" : "add";
                                    this.disabledDates[u](this.formatDate(d, "yyyy-MM-dd"));
                                    let c = this.getCell(d, this.currentViewSingular);
                                    c && c.adpCell.render();
                                }, []);
                            }),
                            m(this, "enableDate", (s) => {
                                this.disableDate(s, !0);
                            }),
                            m(this, "isDateDisabled", (s) => {
                                let r = C(s);
                                return this.disabledDates.has(this.formatDate(r, "yyyy-MM-dd"));
                            }),
                            m(this, "isOtherMonth", (s) => {
                                let { month: r } = v(s);
                                return r !== this.parsedViewDate.month;
                            }),
                            m(this, "isOtherYear", (s) => {
                                let { year: r } = v(s);
                                return r !== this.parsedViewDate.year;
                            }),
                            m(this, "isOtherDecade", (s) => {
                                let { year: r } = v(s),
                                    [h, d] = H(this.viewDate);
                                return r < h || r > d;
                            }),
                            m(this, "_onChangeSelectedDate", (s) => {
                                let { silent: r } = s;
                                setTimeout(() => {
                                    this._setInputValue(),
                                        this.opts.onSelect && !r && this._triggerOnSelect();
                                });
                            }),
                            m(this, "_onChangeFocusedDate", function (s) {
                                let { viewDateTransition: r } =
                                    arguments.length > 1 && arguments[1] !== void 0
                                        ? arguments[1]
                                        : {};
                                if (!s) return;
                                let h = !1;
                                r &&
                                    (h =
                                        t.isOtherMonth(s) ||
                                        t.isOtherYear(s) ||
                                        t.isOtherDecade(s)),
                                    h && t.setViewDate(s),
                                    t.opts.onFocus && t.opts.onFocus({ datepicker: t, date: s });
                            }),
                            m(this, "_onChangeTime", (s) => {
                                let { hours: r, minutes: h } = s,
                                    d = new Date(),
                                    {
                                        lastSelectedDate: u,
                                        opts: { onSelect: c },
                                    } = this,
                                    p = u;
                                u || (p = d);
                                let g = this.getCell(p, this.currentViewSingular),
                                    b = g && g.adpCell;
                                (b && b.isDisabled) ||
                                    (p.setHours(r),
                                        p.setMinutes(h),
                                        u
                                            ? (this._setInputValue(), c && this._triggerOnSelect())
                                            : this.selectDate(p));
                            }),
                            m(this, "_onFocus", (s) => {
                                this.visible || this.show();
                            }),
                            m(this, "_onBlur", (s) => {
                                this.inFocus ||
                                    !this.visible ||
                                    this.opts.isMobile ||
                                    this.hide();
                            }),
                            m(this, "_onMouseDown", (s) => {
                                this.inFocus = !0;
                            }),
                            m(this, "_onMouseUp", (s) => {
                                (this.inFocus = !1), this.$el.focus();
                            }),
                            m(this, "_onResize", () => {
                                this.visible &&
                                    typeof this.opts.position != "function" &&
                                    this.setPosition();
                            }),
                            m(this, "_onClickOverlay", () => {
                                this.visible && this.hide();
                            }),
                            m(this, "getViewDates", function () {
                                let s =
                                    arguments.length > 0 && arguments[0] !== void 0
                                        ? arguments[0]
                                        : l.days;
                                return x.getDatesFunction(s)(t);
                            }),
                            m(this, "isWeekend", (s) => this.opts.weekends.includes(s)),
                            m(this, "getClampedDate", (s) => {
                                let { minDate: r, maxDate: h } = this,
                                    d = s;
                                return h && F(s, h) ? (d = h) : r && K(s, r) && (d = r), d;
                            }),
                            (this.$el = w(e)),
                            !this.$el)
                    )
                        return;
                    (this.$datepicker = M({ className: "air-datepicker" })),
                        (this.opts = G({}, ae, i)),
                        (this.$customContainer =
                            !!this.opts.container && w(this.opts.container)),
                        (this.$altField = w(this.opts.altField || !1));
                    let { view: n, startDate: a } = this.opts;
                    a || (this.opts.startDate = new Date()),
                        this.$el.nodeName === "INPUT" && (this.elIsInput = !0),
                        (this.inited = !1),
                        (this.visible = !1),
                        (this.viewDate = C(this.opts.startDate)),
                        (this.focusDate = !1),
                        (this.initialReadonly = this.$el.getAttribute("readonly")),
                        (this.customHide = !1),
                        (this.currentView = n),
                        (this.selectedDates = []),
                        (this.disabledDates = new Set()),
                        (this.isDestroyed = !1),
                        (this.views = {}),
                        (this.keys = []),
                        (this.rangeDateFrom = ""),
                        (this.rangeDateTo = ""),
                        (this.timepickerIsActive = !1),
                        (this.treatAsInline = this.opts.inline || !this.elIsInput),
                        this.init();
                }
                init() {
                    let {
                        opts: e,
                        treatAsInline: i,
                        opts: {
                            inline: t,
                            isMobile: n,
                            selectedDates: a,
                            keyboardNav: s,
                            onlyTimepicker: r,
                        },
                    } = this,
                        h = w("body");
                    (!te || (te && Y && !h.contains(Y))) &&
                        !t &&
                        this.elIsInput &&
                        !this.$customContainer &&
                        E.buildGlobalContainer(E.defaultGlobalContainerId),
                        !n || V || i || this._createMobileOverlay(),
                        this._handleLocale(),
                        this._bindSubEvents(),
                        this._createMinMaxDates(),
                        this._limitViewDateByMaxMinDates(),
                        this.elIsInput &&
                        (t || this._bindEvents(),
                            s && !r && (this.keyboardNav = new ve({ dp: this, opts: e }))),
                        a && this.selectDate(a, { silent: !0 }),
                        this.opts.visible && !i && this.show(),
                        n && !i && this.$el.setAttribute("readonly", !0),
                        i && this._createComponents();
                }
                _createMobileOverlay() {
                    (V = M({ className: "air-datepicker-overlay" })), Y.appendChild(V);
                }
                _createComponents() {
                    let {
                        opts: e,
                        treatAsInline: i,
                        opts: {
                            inline: t,
                            buttons: n,
                            timepicker: a,
                            position: s,
                            classes: r,
                            onlyTimepicker: h,
                            isMobile: d,
                        },
                    } = this;
                    this._buildBaseHtml(),
                        this.elIsInput && (t || this._setPositionClasses(s)),
                        (!t && this.elIsInput) ||
                        this.$datepicker.classList.add("-inline-"),
                        r && this.$datepicker.classList.add(...r.split(" ")),
                        h && this.$datepicker.classList.add("-only-timepicker-"),
                        d && !i && this._addMobileAttributes(),
                        (this.views[this.currentView] = new x({
                            dp: this,
                            type: this.currentView,
                            opts: e,
                        })),
                        (this.nav = new pe({ dp: this, opts: e })),
                        a && this._addTimepicker(),
                        n && this._addButtons(),
                        this.$content.appendChild(this.views[this.currentView].$el),
                        this.$nav.appendChild(this.nav.$el);
                }
                _destroyComponents() {
                    for (let e in this.views) this.views[e].destroy();
                    (this.views = {}),
                        this.nav.destroy(),
                        this.timepicker && this.timepicker.destroy();
                }
                _addMobileAttributes() {
                    V.addEventListener("click", this._onClickOverlay),
                        this.$datepicker.classList.add("-is-mobile-"),
                        this.$el.setAttribute("readonly", !0);
                }
                _removeMobileAttributes() {
                    V.removeEventListener("click", this._onClickOverlay),
                        this.$datepicker.classList.remove("-is-mobile-"),
                        this.initialReadonly ||
                        this.initialReadonly === "" ||
                        this.$el.removeAttribute("readonly");
                }
                _createMinMaxDates() {
                    let { minDate: e, maxDate: i } = this.opts;
                    (this.minDate = !!e && C(e)), (this.maxDate = !!i && C(i));
                }
                _addTimepicker() {
                    (this.$timepicker = M({ className: "air-datepicker--time" })),
                        this.$datepicker.appendChild(this.$timepicker),
                        (this.timepicker = new ge({ dp: this, opts: this.opts })),
                        this.$timepicker.appendChild(this.timepicker.$el);
                }
                _addButtons() {
                    (this.$buttons = M({ className: "air-datepicker--buttons" })),
                        this.$datepicker.appendChild(this.$buttons),
                        (this.buttons = new me({ dp: this, opts: this.opts })),
                        this.$buttons.appendChild(this.buttons.$el);
                }
                _bindSubEvents() {
                    this.on(l.eventChangeSelectedDate, this._onChangeSelectedDate),
                        this.on(l.eventChangeFocusDate, this._onChangeFocusedDate),
                        this.on(l.eventChangeTime, this._onChangeTime);
                }
                _buildBaseHtml() {
                    let { inline: e } = this.opts;
                    var i, t;
                    this.elIsInput
                        ? e
                            ? ((i = this.$datepicker),
                                (t = this.$el).parentNode.insertBefore(i, t.nextSibling))
                            : this.$container.appendChild(this.$datepicker)
                        : this.$el.appendChild(this.$datepicker),
                        (this.$datepicker.innerHTML =
                            '<i class="air-datepicker--pointer"></i><div class="air-datepicker--navigation"></div><div class="air-datepicker--content"></div>'),
                        (this.$content = w(".air-datepicker--content", this.$datepicker)),
                        (this.$pointer = w(".air-datepicker--pointer", this.$datepicker)),
                        (this.$nav = w(".air-datepicker--navigation", this.$datepicker));
                }
                _handleLocale() {
                    let {
                        locale: e,
                        dateFormat: i,
                        firstDay: t,
                        timepicker: n,
                        onlyTimepicker: a,
                        timeFormat: s,
                        dateTimeSeparator: r,
                    } = this.opts;
                    var h;
                    (this.locale = ((h = e), JSON.parse(JSON.stringify(h)))),
                        i && (this.locale.dateFormat = i),
                        s !== void 0 && s !== "" && (this.locale.timeFormat = s);
                    let { timeFormat: d } = this.locale;
                    if (
                        (t !== "" && (this.locale.firstDay = t),
                            n && typeof i != "function")
                    ) {
                        let u = d ? r : "";
                        this.locale.dateFormat = [this.locale.dateFormat, d || ""].join(
                            u
                        );
                    }
                    a &&
                        typeof i != "function" &&
                        (this.locale.dateFormat = this.locale.timeFormat);
                }
                _setPositionClasses(e) {
                    if (typeof e == "function")
                        return void this.$datepicker.classList.add("-custom-position-");
                    let i = (e = e.split(" "))[0],
                        t = `air-datepicker -${i}-${e[1]}- -from-${i}-`;
                    this.$datepicker.classList.add(...t.split(" "));
                }
                _bindEvents() {
                    this.$el.addEventListener(this.opts.showEvent, this._onFocus),
                        this.$el.addEventListener("blur", this._onBlur),
                        this.$datepicker.addEventListener("mousedown", this._onMouseDown),
                        this.$datepicker.addEventListener("mouseup", this._onMouseUp),
                        window.addEventListener("resize", this._onResize);
                }
                _limitViewDateByMaxMinDates() {
                    let { viewDate: e, minDate: i, maxDate: t } = this;
                    t && F(e, t) && this.setViewDate(t),
                        i && K(e, i) && this.setViewDate(i);
                }
                formatDate() {
                    let e =
                        arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : this.viewDate,
                        i = arguments.length > 1 ? arguments[1] : void 0;
                    if (((e = C(e)), !(e instanceof Date))) return;
                    let t = i,
                        n = this.locale,
                        a = v(e),
                        s = a.dayPeriod,
                        r = H(e),
                        h = E.replacer,
                        d = {
                            T: e.getTime(),
                            m: a.minutes,
                            mm: a.fullMinutes,
                            h: a.hours12,
                            hh: a.fullHours12,
                            H: a.hours,
                            HH: a.fullHours,
                            aa: s,
                            AA: s.toUpperCase(),
                            E: n.daysShort[a.day],
                            EEEE: n.days[a.day],
                            d: a.date,
                            dd: a.fullDate,
                            M: a.month + 1,
                            MM: a.fullMonth,
                            MMM: n.monthsShort[a.month],
                            MMMM: n.months[a.month],
                            yy: a.year.toString().slice(-2),
                            yyyy: a.year,
                            yyyy1: r[0],
                            yyyy2: r[1],
                        };
                    for (let [u, c] of Object.entries(d)) t = h(t, ee(u), c);
                    return t;
                }
                down(e) {
                    this._handleUpDownActions(e, "down");
                }
                up(e) {
                    this._handleUpDownActions(e, "up");
                }
                selectDate(e) {
                    let i,
                        t =
                            arguments.length > 1 && arguments[1] !== void 0
                                ? arguments[1]
                                : {},
                        { currentView: n, parsedViewDate: a, selectedDates: s } = this,
                        { updateTime: r } = t,
                        {
                            moveToOtherMonthsOnSelect: h,
                            moveToOtherYearsOnSelect: d,
                            multipleDates: u,
                            range: c,
                            autoClose: p,
                            onBeforeSelect: g,
                        } = this.opts,
                        b = s.length;
                    if (Array.isArray(e))
                        return (
                            e.forEach((f) => {
                                this.selectDate(f, t);
                            }),
                            new Promise((f) => {
                                setTimeout(f);
                            })
                        );
                    if ((e = C(e)) instanceof Date) {
                        if (g && !g({ date: e, datepicker: this }))
                            return Promise.resolve();
                        if (
                            (n === l.days &&
                                e.getMonth() !== a.month &&
                                h &&
                                (i = new Date(e.getFullYear(), e.getMonth(), 1)),
                                n === l.years &&
                                e.getFullYear() !== a.year &&
                                d &&
                                (i = new Date(e.getFullYear(), 0, 1)),
                                i && this.setViewDate(i),
                                u && !c)
                        ) {
                            if (b === u) return;
                            this._checkIfDateIsSelected(e) || s.push(e);
                        } else if (c)
                            switch (b) {
                                case 1:
                                    s.push(e),
                                        this.rangeDateTo || (this.rangeDateTo = e),
                                        F(this.rangeDateFrom, this.rangeDateTo) &&
                                        ((this.rangeDateTo = this.rangeDateFrom),
                                            (this.rangeDateFrom = e)),
                                        (this.selectedDates = [
                                            this.rangeDateFrom,
                                            this.rangeDateTo,
                                        ]);
                                    break;
                                case 2:
                                    (this.selectedDates = [e]),
                                        (this.rangeDateFrom = e),
                                        (this.rangeDateTo = "");
                                    break;
                                default:
                                    (this.selectedDates = [e]), (this.rangeDateFrom = e);
                            }
                        else this.selectedDates = [e];
                        return (
                            this.trigger(l.eventChangeSelectedDate, {
                                action: l.actionSelectDate,
                                silent: t?.silent,
                                date: e,
                                updateTime: r,
                            }),
                            this._updateLastSelectedDate(e),
                            p &&
                            !this.timepickerIsActive &&
                            this.visible &&
                            (u || c ? c && b === 1 && this.hide() : this.hide()),
                            new Promise((f) => {
                                setTimeout(f);
                            })
                        );
                    }
                }
                unselectDate(e) {
                    let i = this.selectedDates,
                        t = this;
                    if ((e = C(e)) instanceof Date)
                        return i.some((n, a) => {
                            if (D(n, e))
                                return (
                                    i.splice(a, 1),
                                    t.selectedDates.length
                                        ? ((t.rangeDateTo = ""),
                                            (t.rangeDateFrom = i[0]),
                                            t._updateLastSelectedDate(
                                                t.selectedDates[t.selectedDates.length - 1]
                                            ))
                                        : ((t.rangeDateFrom = ""),
                                            (t.rangeDateTo = ""),
                                            t._updateLastSelectedDate(!1)),
                                    this.trigger(l.eventChangeSelectedDate, {
                                        action: l.actionUnselectDate,
                                        date: e,
                                    }),
                                    !0
                                );
                        });
                }
                replaceDate(e, i) {
                    let t = this.selectedDates.find((a) => D(a, e, this.currentView)),
                        n = this.selectedDates.indexOf(t);
                    n < 0 ||
                        D(this.selectedDates[n], i, this.currentView) ||
                        ((this.selectedDates[n] = i),
                            this.trigger(l.eventChangeSelectedDate, {
                                action: l.actionSelectDate,
                                date: i,
                                updateTime: !0,
                            }),
                            this._updateLastSelectedDate(i));
                }
                clear() {
                    let e =
                        arguments.length > 0 && arguments[0] !== void 0
                            ? arguments[0]
                            : {};
                    return (
                        (this.selectedDates = []),
                        (this.rangeDateFrom = !1),
                        (this.rangeDateTo = !1),
                        (this.lastSelectedDate = !1),
                        this.trigger(l.eventChangeSelectedDate, {
                            action: l.actionUnselectDate,
                            silent: e.silent,
                        }),
                        new Promise((i) => {
                            setTimeout(i);
                        })
                    );
                }
                show() {
                    let { onShow: e, isMobile: i } = this.opts;
                    this._cancelScheduledCall(),
                        this.visible || this.hideAnimation || this._createComponents(),
                        this.setPosition(this.opts.position),
                        this.$datepicker.classList.add("-active-"),
                        (this.visible = !0),
                        e && this._scheduleCallAfterTransition(e),
                        i && this._showMobileOverlay();
                }
                hide() {
                    let { onHide: e, isMobile: i } = this.opts,
                        t = this._hasTransition();
                    (this.visible = !1),
                        (this.hideAnimation = !0),
                        this.$datepicker.classList.remove("-active-"),
                        this.customHide && this.customHide(),
                        this.elIsInput && this.$el.blur(),
                        this._scheduleCallAfterTransition((n) => {
                            !this.customHide &&
                                ((n && t) || (!n && !t)) &&
                                this._finishHide(),
                                e && e(n);
                        }),
                        i && V.classList.remove("-active-");
                }
                _triggerOnSelect() {
                    let e = [],
                        i = [],
                        {
                            selectedDates: t,
                            locale: n,
                            opts: { onSelect: a, multipleDates: s, range: r },
                        } = this,
                        h = s || r,
                        d = typeof n.dateFormat == "function";
                    t.length &&
                        ((e = t.map(z)),
                            (i = d
                                ? s
                                    ? n.dateFormat(e)
                                    : e.map((u) => n.dateFormat(u))
                                : e.map((u) => this.formatDate(u, n.dateFormat)))),
                        a({
                            date: h ? e : e[0],
                            formattedDate: h ? i : i[0],
                            datepicker: this,
                        });
                }
                _handleAlreadySelectedDates(e, i) {
                    let { selectedDates: t, rangeDateFrom: n, rangeDateTo: a } = this,
                        { range: s, toggleSelected: r } = this.opts,
                        h = t.length,
                        d = typeof r == "function" ? r({ datepicker: this, date: i }) : r,
                        u = !!(s && h === 1 && e),
                        c = u ? z(i) : i;
                    (s && !d && (h !== 2 && this.selectDate(c), h === 2 && D(n, a))) ||
                        (d
                            ? this.unselectDate(c)
                            : this._updateLastSelectedDate(u ? c : e));
                }
                _handleUpDownActions(e, i) {
                    if (
                        !((e = C(e || this.focusDate || this.viewDate)) instanceof Date)
                    )
                        return;
                    let t = i === "up" ? this.viewIndex + 1 : this.viewIndex - 1;
                    t > 2 && (t = 2),
                        t < 0 && (t = 0),
                        this.setViewDate(new Date(e.getFullYear(), e.getMonth(), 1)),
                        this.setCurrentView(this.viewIndexes[t]);
                }
                getCell(e) {
                    let i =
                        arguments.length > 1 && arguments[1] !== void 0
                            ? arguments[1]
                            : l.day;
                    if (!((e = C(e)) instanceof Date)) return;
                    let { year: t, month: n, date: a } = v(e),
                        s = `[data-year="${t}"]`,
                        r = `[data-month="${n}"]`,
                        h = {
                            [l.day]: `${s}${r}[data-date="${a}"]`,
                            [l.month]: `${s}${r}`,
                            [l.year]: `${s}`,
                        };
                    return this.views[this.currentView]
                        ? this.views[this.currentView].$el.querySelector(h[i])
                        : void 0;
                }
                _showMobileOverlay() {
                    V.classList.add("-active-");
                }
                _hasTransition() {
                    return (
                        window
                            .getComputedStyle(this.$datepicker)
                            .getPropertyValue("transition-duration")
                            .split(", ")
                            .reduce((e, i) => parseFloat(i) + e, 0) > 0
                    );
                }
                get shouldUpdateDOM() {
                    return this.visible || this.treatAsInline;
                }
                get parsedViewDate() {
                    return v(this.viewDate);
                }
                get currentViewSingular() {
                    return this.currentView.slice(0, -1);
                }
                get curDecade() {
                    return H(this.viewDate);
                }
                get viewIndex() {
                    return this.viewIndexes.indexOf(this.currentView);
                }
                get isFinalView() {
                    return this.currentView === l.years;
                }
                get hasSelectedDates() {
                    return this.selectedDates.length > 0;
                }
                get isMinViewReached() {
                    return (
                        this.currentView === this.opts.minView ||
                        this.currentView === l.days
                    );
                }
                get $container() {
                    return this.$customContainer || Y;
                }
                static replacer(e, i, t) {
                    return e.replace(i, function (n, a, s, r) {
                        return a + t + r;
                    });
                }
            }
            var he;
            return (
                m(E, "defaults", ae),
                m(E, "version", "3.5.3"),
                m(E, "defaultGlobalContainerId", "air-datepicker-global-container"),
                (he = E.prototype),
                Object.assign(he, ye),
                S.default
            );
        })();
    });
})(le);
export var ke = le.exports;